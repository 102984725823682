import { Link } from 'gatsby';
import React from 'react';

interface PressReleaseTileProps {
  url: string;
  title: string;
  category: string;
  image: string;
  excerpt?: string;
}

const PressReleaseTile: React.FC<PressReleaseTileProps> = ({
  url,
  title,
  category,
  image,
  excerpt,
}) => {
  return (
    <Link
      to={url}
      className="transition-opacity duration-200 ease-in-out hover:opacity-50 block"
    >
      <div className="aspect-w-16 aspect-h-11 mb-8">
        <img src={image} alt={title} className="w-full inset-0 object-cover" />
      </div>
      <div className="flex items-center text-sm uppercase text-aqua font-bold leading-none mb-2">
        <svg
          className="mr-2"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="13"
          fill="none"
          viewBox="0 0 15 13"
        >
          <path
            fill="#4EC0C4"
            d="M4.344 6.118H.918v4.197c0 .856.6 1.627 1.456 1.798 1.028.172 1.97-.685 1.97-1.713V6.118zM13.765.123H6.913c-.514 0-.856.342-.856.856V10.4c0 .6-.171 1.2-.514 1.713h6.51c1.455 0 2.569-1.113 2.569-2.57V.98c0-.514-.343-.856-.857-.856z"
          ></path>
        </svg>
        {category || 'Press Release'}
      </div>

      <h2 className="text-2xl leading-tight font-bold tracking-tight">
        {title}
      </h2>
      {excerpt && <p className="mt-2 tracking-tighter">{excerpt}</p>}
    </Link>
  );
};

export default PressReleaseTile;
