import React from 'react';
import PressReleaseTile from 'components/molecules/PressReleaseTile';

interface PressRelease {
  url: string;
  title: string;
  category: string;
  image: string;
}

interface PressReleaseGridSectionProps {
  title?: string;
  items: PressRelease[];
}

const PressReleaseGridSection: React.FC<PressReleaseGridSectionProps> = ({
  title,
  items,
}) => {
  return (
    <div
      className={`container max-w-screen-xl mx-auto my-20 md:my-32 px-6 lg:px-md`}
    >
      {title && <h2 className="mb-16 text-center u-h2">{title}</h2>}

      <div className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-6">
        {items.map((item, index) => (
          <div key={index} className="press-tile-wrapper mb-8">
            <PressReleaseTile {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PressReleaseGridSection;
