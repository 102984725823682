import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ContactHero from 'components/organisms/ContactHero';
import CtaSection from 'components/organisms/CtaSection';
import PressReleaseGridSection from 'components/organisms/PressReleaseGridSection';
// import PressResourcesSection from 'components/organisms/PressResourcesSection';

const PressContent = ({ hero, cta, resources = [] }) => {
  return (
    <>
      <ContactHero
        title={hero?.title}
        body={hero?.description}
        background="bg-aqua bg-opacity-15 "
      />

      <PressReleaseGridSection items={resources} />

      <div className="border-b border-border-grey" />

      {/* <PressResourcesSection
      logoPackageUrl={data.logoPackage?.file?.url}
      leadershipPackageUrl={data.leadershipPhotosPackage?.file?.url}
    /> */}

      <CtaSection headline={cta?.headline} />
    </>
  );
};

const RESOURCES_FIXTURE = [
  {
    category: '',
    url: '#',
    title:
      'Skupos Named to the 2020 CB Insights Retail Tech 100 - List of Most Innovative B2B Retail Startups',
    image: '/uploads/graphic_blog_cbinsights_1440x720px.png',
  },
];

export const PressPreview = ({ entry }) => {
  return (
    <PressContent {...entry.get('data').toJS()} resources={RESOURCES_FIXTURE} />
  );
};

const PressPage = ({
  data: {
    markdownRemark: { frontmatter },
    presses,
  },
}) => {
  const { seo } = frontmatter;

  const resources = presses.edges.map(({ node }) => {
    const { title, slug, featuredImage } = node.frontmatter || {};

    return {
      category: '',
      url: `/press/${slug}`,
      title,
      image: featuredImage,
    };
  });

  return (
    <Layout headerBackground="bg-aqua bg-opacity-15">
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <PressContent {...frontmatter} resources={resources} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PressPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/presses/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
        }
        cta {
          headline
        }
      }
    }
    presses: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "press-release" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
          }
        }
      }
    }
  }
`;
export default PressPage;
